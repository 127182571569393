


























import Utils from 'tradingmate-modules/src/Utils'
import { Vue, Component } from 'vue-property-decorator'
import Config from '@/config'

@Component({
  components: {
  }
})

export default class SignUpBanner extends Vue {
  private getCharacter = Utils.getCharacter()

  ctaClicked (): void {
    const url = Config.WebsiteUrls.Console + '/account/register'
    window.open(url, '_self')
  }
}

